import React from 'react'
import { Link } from 'gatsby'

const Pagination = ({ pageInfo }) => {
  const nextPage = pageInfo.currentPage + 1
  const prevPage = pageInfo.currentPage - 1

  let pages = []

  for (let page = 1; page <= pageInfo.pageCount; page++) {
    pageInfo.currentPage === page
      ? pages.push(<span className="page-numbers current">{page}</span>)
      : pages.push(
          <Link
            to={page === 1 ? `/blog` : `/blog/page/${page}`}
            className="page-numbers"
          >
            {page}
          </Link>
        )
  }

  return (
    <div className="col-lg-12 col-md-12">
      <div className="pagination-area text-center">
        {pageInfo.hasPreviousPage ? (
          <Link
            to={prevPage === 1 ? '/blog' : `/blog/page/${prevPage}`}
            className="prev page-numbers"
          >
            <i className="bx bx-chevrons-left"></i>
          </Link>
        ) : (
          <span className="prev page-numbers">
            <i className="bx bx-chevrons-left"></i>
          </span>
        )}
        {pages}

        {pageInfo.hasNextPage ? (
          <Link to={`/blog/page/${nextPage}`} className="next page-numbers">
            <i className="bx bx-chevrons-right"></i>
          </Link>
        ) : (
          <span className="prev page-numbers">
            <i className="bx bx-chevrons-right"></i>
          </span>
        )}
      </div>
    </div>
  )
}

export default Pagination
